import { Icon } from '@types';

import React from 'react';

const ChevronLeft: React.FC<Icon> = ({ fill }: Icon) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z" fill={fill} />
  </svg>
);

export default ChevronLeft;
