module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Art of Abstraction","short_name":"T·A·O·A","start_url":"/","display":"minimal-ui","icon":"static/favicons/favicon-32x32.png","theme_color":"#ffffff","background_color":"#ffffff","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-126839741-1","head":false,"anonymize":false,"respectDNT":true,"defer":true,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/node/work/src/templates/post.template.tsx"},"remarkPlugins":[null,null,null],"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-emojis","options":{"active":true,"size":64}},{"resolve":"gatsby-remark-images","options":{"maxWidth":10000,"linkImagesToOriginal":false,"quality":80,"withWebp":true}}],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/node/work"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
